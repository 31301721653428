angular.module('QoipApp').factory('QoipFieldWeatherDataFactory', function () {
    var total_rainfall_ts = {
        "name": "Total Rainfall",
        "color": "#4572A7",
        "type": "column",
        "yAxis": 0,
        "data": [
            {
                "x": 1530403200000,
                "y": 1.28
            },
            {
                "x": 1530489600000,
                "y": 2
            },
            {
                "x": 1530576000000,
                "y": 2.14
            },
            {
                "x": 1530662400000,
                "y": 4.05
            },
            {
                "x": 1530748800000,
                "y": 7.84
            },
            {
                "x": 1530835200000,
                "y": 2.25
            },
            {
                "x": 1530921600000,
                "y": 3.59
            },
            {
                "x": 1531008000000,
                "y": 6.3
            },
            {
                "x": 1531094400000,
                "y": 2.74
            },
            {
                "x": 1531180800000,
                "y": 3.41
            },
            {
                "x": 1531267200000,
                "y": 4.47
            },
            {
                "x": 1531353600000,
                "y": 7.32
            },
            {
                "x": 1531440000000,
                "y": 1.96
            },
            {
                "x": 1531526400000,
                "y": 5.11
            },
            {
                "x": 1531612800000,
                "y": 13.05
            },
            {
                "x": 1531699200000,
                "y": 14.8
            },
            {
                "x": 1531785600000,
                "y": 5.14
            },
            {
                "x": 1531872000000,
                "y": 1.02
            },
            {
                "x": 1531958400000,
                "y": 1.4
            },
            {
                "x": 1532044800000,
                "y": 3.63
            },
            {
                "x": 1532131200000,
                "y": 0.59
            },
            {
                "x": 1532217600000,
                "y": 4.03
            },
            {
                "x": 1532304000000,
                "y": 2.33
            },
            {
                "x": 1532390400000,
                "y": 0.71
            },
            {
                "x": 1532476800000,
                "y": 0.32
            },
            {
                "x": 1532563200000,
                "y": 0.7
            },
            {
                "x": 1532649600000,
                "y": 0.4
            },
            {
                "x": 1532736000000,
                "y": 0.11
            },
            {
                "x": 1532822400000,
                "y": 0.24
            },
            {
                "x": 1532908800000,
                "y": 0.11
            },
            {
                "x": 1532995200000,
                "y": 0.5
            },
            {
                "x": 1533081600000,
                "y": 1.01
            },
            {
                "x": 1533168000000,
                "y": 0.29
            },
            {
                "x": 1533254400000,
                "y": 0.41
            },
            {
                "x": 1533340800000,
                "y": 1.94
            },
            {
                "x": 1533427200000,
                "y": 0.45
            },
            {
                "x": 1533513600000,
                "y": 1.09
            },
            {
                "x": 1533600000000,
                "y": 0.49
            },
            {
                "x": 1533686400000,
                "y": 1.27
            },
            {
                "x": 1533772800000,
                "y": 0.15
            },
            {
                "x": 1533859200000,
                "y": 0.57
            },
            {
                "x": 1533945600000,
                "y": 0.32
            },
            {
                "x": 1534032000000,
                "y": 2.89
            },
            {
                "x": 1534204800000,
                "y": 1.24
            },
            {
                "x": 1534291200000,
                "y": 3.96
            },
            {
                "x": 1534377600000,
                "y": 29.51
            },
            {
                "x": 1534464000000,
                "y": 34.02
            },
            {
                "x": 1534550400000,
                "y": 0.62
            },
            {
                "x": 1534636800000,
                "y": 0.71
            },
            {
                "x": 1534723200000,
                "y": 4.14
            },
            {
                "x": 1534809600000,
                "y": 11.78
            },
            {
                "x": 1534896000000,
                "y": 1.16
            },
            {
                "x": 1534982400000,
                "y": 0.73
            },
            {
                "x": 1535068800000,
                "y": 1.09
            },
            {
                "x": 1535155200000,
                "y": 0.38
            },
            {
                "x": 1535241600000,
                "y": 1.97
            },
            {
                "x": 1535328000000,
                "y": 2.11
            },
            {
                "x": 1535414400000,
                "y": 0.85
            },
            {
                "x": 1535500800000,
                "y": 0.11
            },
            {
                "x": 1535587200000,
                "y": 0
            },
            {
                "x": 1535673600000,
                "y": 0.31
            },
            {
                "x": 1535760000000,
                "y": 0.53
            },
            {
                "x": 1535846400000,
                "y": 0.34
            },
            {
                "x": 1535932800000,
                "y": 0.53
            },
            {
                "x": 1536019200000,
                "y": 0.44
            },
            {
                "x": 1536105600000,
                "y": 0.04
            },
            {
                "x": 1536192000000,
                "y": 0.02
            },
            {
                "x": 1536278400000,
                "y": 0.29
            },
            {
                "x": 1536364800000,
                "y": 0.06
            },
            {
                "x": 1536451200000,
                "y": 0.01
            },
            {
                "x": 1536537600000,
                "y": 2.49
            },
            {
                "x": 1536624000000,
                "y": 0.57
            },
            {
                "x": 1536710400000,
                "y": 0.07
            },
            {
                "x": 1536796800000,
                "y": 0.08
            },
            {
                "x": 1536883200000,
                "y": 2.03
            },
            {
                "x": 1536969600000,
                "y": 3.87
            },
            {
                "x": 1537056000000,
                "y": 7.03
            },
            {
                "x": 1537142400000,
                "y": 1.03
            },
            {
                "x": 1537228800000,
                "y": 9.06
            },
            {
                "x": 1537315200000,
                "y": 9.11
            },
            {
                "x": 1537401600000,
                "y": 2.63
            },
            {
                "x": 1537488000000,
                "y": 1.61
            },
            {
                "x": 1537574400000,
                "y": 0.52
            },
            {
                "x": 1537660800000,
                "y": 3.51
            },
            {
                "x": 1537747200000,
                "y": 1.96
            },
            {
                "x": 1537833600000,
                "y": 2.9
            },
            {
                "x": 1537920000000,
                "y": 2.94
            },
            {
                "x": 1538006400000,
                "y": 7.58
            },
            {
                "x": 1538092800000,
                "y": 4.78
            },
            {
                "x": 1538179200000,
                "y": 0.78
            },
            {
                "x": 1538265600000,
                "y": 0.76
            },
            {
                "x": 1538352000000,
                "y": 3.78
            },
            {
                "x": 1538438400000,
                "y": 1.6
            },
            {
                "x": 1538524800000,
                "y": 2.96
            },
            {
                "x": 1538611200000,
                "y": 1.5
            },
            {
                "x": 1538697600000,
                "y": 0.14
            },
            {
                "x": 1538784000000,
                "y": 0.2
            },
            {
                "x": 1538870400000,
                "y": 0.01
            },
            {
                "x": 1538956800000,
                "y": 0
            },
            {
                "x": 1539043200000,
                "y": 0
            },
            {
                "x": 1539129600000,
                "y": 0
            },
            {
                "x": 1539216000000,
                "y": 0
            },
            {
                "x": 1539302400000,
                "y": 0
            },
            {
                "x": 1539388800000,
                "y": 0
            },
            {
                "x": 1539475200000,
                "y": 0
            },
            {
                "x": 1539561600000,
                "y": 0
            },
            {
                "x": 1539648000000,
                "y": 0
            },
            {
                "x": 1539734400000,
                "y": 0.07
            },
            {
                "x": 1539820800000,
                "y": 1.28
            },
            {
                "x": 1539907200000,
                "y": 0.09
            },
            {
                "x": 1539993600000,
                "y": 0.03
            },
            {
                "x": 1540080000000,
                "y": 0.1
            },
            {
                "x": 1540166400000,
                "y": 0.74
            },
            {
                "x": 1540252800000,
                "y": 0
            },
            {
                "x": 1540339200000,
                "y": 0
            },
            {
                "x": 1540425600000,
                "y": 0
            },
            {
                "x": 1540512000000,
                "y": 0
            },
            {
                "x": 1540598400000,
                "y": 0.01
            },
            {
                "x": 1540684800000,
                "y": 0
            },
            {
                "x": 1540857600000,
                "y": 0
            },
            {
                "x": 1540944000000,
                "y": 0
            },
            {
                "x": 1541030400000,
                "y": 0
            },
            {
                "x": 1541116800000,
                "y": 0
            },
            {
                "x": 1541203200000,
                "y": 0.55
            },
            {
                "x": 1541289600000,
                "y": 5.73
            },
            {
                "x": 1541376000000,
                "y": 12.25
            },
            {
                "x": 1541462400000,
                "y": 3.34
            },
            {
                "x": 1541548800000,
                "y": 0
            },
            {
                "x": 1541635200000,
                "y": 0
            },
            {
                "x": 1541721600000,
                "y": 0
            },
            {
                "x": 1541808000000,
                "y": 0
            },
            {
                "x": 1541894400000,
                "y": 0
            },
            {
                "x": 1541980800000,
                "y": 0
            },
            {
                "x": 1542067200000,
                "y": 0
            },
            {
                "x": 1542153600000,
                "y": 0
            },
            {
                "x": 1542240000000,
                "y": 0
            },
            {
                "x": 1542326400000,
                "y": 0
            },
            {
                "x": 1542412800000,
                "y": 0
            },
            {
                "x": 1542499200000,
                "y": 0.27
            },
            {
                "x": 1542585600000,
                "y": 18.2
            },
            {
                "x": 1542672000000,
                "y": 5.18
            },
            {
                "x": 1542758400000,
                "y": 0.13
            },
            {
                "x": 1542844800000,
                "y": 0
            },
            {
                "x": 1542931200000,
                "y": 0.05
            },
            {
                "x": 1543017600000,
                "y": 0
            },
            {
                "x": 1543104000000,
                "y": 0
            },
            {
                "x": 1543190400000,
                "y": 0.03
            },
            {
                "x": 1543449600000,
                "y": 0
            },
            {
                "x": 1543536000000,
                "y": 0
            },
            {
                "x": 1543622400000,
                "y": 0
            },
            {
                "x": 1543708800000,
                "y": 0
            },
            {
                "x": 1543795200000,
                "y": 0
            },
            {
                "x": 1543881600000,
                "y": 0
            },
            {
                "x": 1543968000000,
                "y": 0
            },
            {
                "x": 1544054400000,
                "y": 0
            },
            {
                "x": 1544140800000,
                "y": 0
            },
            {
                "x": 1544227200000,
                "y": 0
            },
            {
                "x": 1544313600000,
                "y": 0
            },
            {
                "x": 1544400000000,
                "y": 0
            },
            {
                "x": 1544486400000,
                "y": 0
            },
            {
                "x": 1544572800000,
                "y": 0
            },
            {
                "x": 1544659200000,
                "y": 0
            },
            {
                "x": 1544745600000,
                "y": 0.03
            },
            {
                "x": 1544832000000,
                "y": 0
            },
            {
                "x": 1544918400000,
                "y": 0
            },
            {
                "x": 1545004800000,
                "y": 0
            },
            {
                "x": 1545091200000,
                "y": 0.02
            },
            {
                "x": 1545177600000,
                "y": 0
            },
            {
                "x": 1545264000000,
                "y": 0
            },
            {
                "x": 1545350400000,
                "y": 0.02
            },
            {
                "x": 1545436800000,
                "y": 0
            },
            {
                "x": 1545523200000,
                "y": 0
            },
            {
                "x": 1545609600000,
                "y": 0
            },
            {
                "x": 1545696000000,
                "y": 0
            },
            {
                "x": 1545782400000,
                "y": 0
            },
            {
                "x": 1545868800000,
                "y": 0
            },
            {
                "x": 1545955200000,
                "y": 0
            },
            {
                "x": 1546041600000,
                "y": 0
            },
            {
                "x": 1546128000000,
                "y": 0
            },
            {
                "x": 1546214400000,
                "y": 0
            },
            {
                "x": 1546300800000,
                "y": 0
            },
            {
                "x": 1546387200000,
                "y": 0
            },
            {
                "x": 1546473600000,
                "y": 0
            },
            {
                "x": 1546560000000,
                "y": 0
            },
            {
                "x": 1546646400000,
                "y": 0
            },
            {
                "x": 1546732800000,
                "y": 0.01
            },
            {
                "x": 1546819200000,
                "y": 0
            },
            {
                "x": 1546905600000,
                "y": 0
            },
            {
                "x": 1546992000000,
                "y": 0
            },
            {
                "x": 1547078400000,
                "y": 0.01
            },
            {
                "x": 1547164800000,
                "y": 0
            },
            {
                "x": 1547251200000,
                "y": 0
            },
            {
                "x": 1547337600000,
                "y": 0
            },
            {
                "x": 1547424000000,
                "y": 0
            },
            {
                "x": 1547510400000,
                "y": 0
            }
        ],
        "marker": {
            "enabled": false
        },
        "tooltip": {
            "valueSuffix": " mm",
            "valueDecimals": 2
        },
        "id": "cc-1"
    };
    var avg_temperature_ts = {
        "name": "Avg Temperature",
        "type": "spline",
        "color": "#FF4000",
        "yAxis": 1,
        "data": [
            {
                "x": 1530403200000,
                "y": 27.89
            },
            {
                "x": 1530489600000,
                "y": 27.44
            },
            {
                "x": 1530576000000,
                "y": 26.06
            },
            {
                "x": 1530662400000,
                "y": 25.55
            },
            {
                "x": 1530748800000,
                "y": 25.28
            },
            {
                "x": 1530835200000,
                "y": 26.15
            },
            {
                "x": 1530921600000,
                "y": 24.96
            },
            {
                "x": 1531008000000,
                "y": 24.74
            },
            {
                "x": 1531094400000,
                "y": 24.5
            },
            {
                "x": 1531180800000,
                "y": 24.64
            },
            {
                "x": 1531267200000,
                "y": 24.47
            },
            {
                "x": 1531353600000,
                "y": 23.71
            },
            {
                "x": 1531440000000,
                "y": 25.1
            },
            {
                "x": 1531526400000,
                "y": 25.02
            },
            {
                "x": 1531612800000,
                "y": 24.54
            },
            {
                "x": 1531699200000,
                "y": 23.78
            },
            {
                "x": 1531785600000,
                "y": 23.81
            },
            {
                "x": 1531872000000,
                "y": 24.91
            },
            {
                "x": 1531958400000,
                "y": 25.41
            },
            {
                "x": 1532044800000,
                "y": 24.82
            },
            {
                "x": 1532131200000,
                "y": 25.12
            },
            {
                "x": 1532217600000,
                "y": 24.18
            },
            {
                "x": 1532304000000,
                "y": 24.23
            },
            {
                "x": 1532390400000,
                "y": 24.7
            },
            {
                "x": 1532476800000,
                "y": 24.99
            },
            {
                "x": 1532563200000,
                "y": 25.45
            },
            {
                "x": 1532649600000,
                "y": 25.81
            },
            {
                "x": 1532736000000,
                "y": 26.89
            },
            {
                "x": 1532822400000,
                "y": 26.93
            },
            {
                "x": 1532908800000,
                "y": 26.64
            },
            {
                "x": 1532995200000,
                "y": 26.28
            },
            {
                "x": 1533081600000,
                "y": 26.02
            },
            {
                "x": 1533168000000,
                "y": 26.2
            },
            {
                "x": 1533254400000,
                "y": 26.36
            },
            {
                "x": 1533340800000,
                "y": 25.57
            },
            {
                "x": 1533427200000,
                "y": 25.83
            },
            {
                "x": 1533513600000,
                "y": 25.99
            },
            {
                "x": 1533600000000,
                "y": 25.99
            },
            {
                "x": 1533686400000,
                "y": 25.56
            },
            {
                "x": 1533772800000,
                "y": 25.2
            },
            {
                "x": 1533859200000,
                "y": 26.29
            },
            {
                "x": 1533945600000,
                "y": 25.39
            },
            {
                "x": 1534032000000,
                "y": 25
            },
            {
                "x": 1534204800000,
                "y": 25.88
            },
            {
                "x": 1534291200000,
                "y": 24.89
            },
            {
                "x": 1534377600000,
                "y": 22.91
            },
            {
                "x": 1534464000000,
                "y": 22.55
            },
            {
                "x": 1534550400000,
                "y": 24.17
            },
            {
                "x": 1534636800000,
                "y": 24.59
            },
            {
                "x": 1534723200000,
                "y": 23.43
            },
            {
                "x": 1534809600000,
                "y": 22.69
            },
            {
                "x": 1534896000000,
                "y": 23.9
            },
            {
                "x": 1534982400000,
                "y": 24.36
            },
            {
                "x": 1535068800000,
                "y": 24.66
            },
            {
                "x": 1535155200000,
                "y": 24.31
            },
            {
                "x": 1535241600000,
                "y": 24.67
            },
            {
                "x": 1535328000000,
                "y": 24.21
            },
            {
                "x": 1535414400000,
                "y": 24.42
            },
            {
                "x": 1535500800000,
                "y": 25.07
            },
            {
                "x": 1535587200000,
                "y": 25.54
            },
            {
                "x": 1535673600000,
                "y": 25.26
            },
            {
                "x": 1535760000000,
                "y": 25.12
            },
            {
                "x": 1535846400000,
                "y": 25.33
            },
            {
                "x": 1535932800000,
                "y": 25.29
            },
            {
                "x": 1536019200000,
                "y": 24.82
            },
            {
                "x": 1536105600000,
                "y": 24.87
            },
            {
                "x": 1536192000000,
                "y": 24.91
            },
            {
                "x": 1536278400000,
                "y": 24.95
            },
            {
                "x": 1536364800000,
                "y": 24.96
            },
            {
                "x": 1536451200000,
                "y": 25.09
            },
            {
                "x": 1536537600000,
                "y": 24.9
            },
            {
                "x": 1536624000000,
                "y": 25.75
            },
            {
                "x": 1536710400000,
                "y": 26.13
            },
            {
                "x": 1536796800000,
                "y": 25.35
            },
            {
                "x": 1536883200000,
                "y": 25.83
            },
            {
                "x": 1536969600000,
                "y": 26.05
            },
            {
                "x": 1537056000000,
                "y": 24.95
            },
            {
                "x": 1537142400000,
                "y": 25.47
            },
            {
                "x": 1537228800000,
                "y": 24.78
            },
            {
                "x": 1537315200000,
                "y": 25.29
            },
            {
                "x": 1537401600000,
                "y": 26.08
            },
            {
                "x": 1537488000000,
                "y": 26.71
            },
            {
                "x": 1537574400000,
                "y": 25.8
            },
            {
                "x": 1537660800000,
                "y": 25.86
            },
            {
                "x": 1537747200000,
                "y": 26.74
            },
            {
                "x": 1537833600000,
                "y": 27.16
            },
            {
                "x": 1537920000000,
                "y": 27.4
            },
            {
                "x": 1538006400000,
                "y": 27
            },
            {
                "x": 1538092800000,
                "y": 26.81
            },
            {
                "x": 1538179200000,
                "y": 28.26
            },
            {
                "x": 1538265600000,
                "y": 28.63
            },
            {
                "x": 1538352000000,
                "y": 28.27
            },
            {
                "x": 1538438400000,
                "y": 27.95
            },
            {
                "x": 1538524800000,
                "y": 28.28
            },
            {
                "x": 1538611200000,
                "y": 27.61
            },
            {
                "x": 1538697600000,
                "y": 27.8
            },
            {
                "x": 1538784000000,
                "y": 27.97
            },
            {
                "x": 1538870400000,
                "y": 28.35
            },
            {
                "x": 1538956800000,
                "y": 27.94
            },
            {
                "x": 1539043200000,
                "y": 28.21
            },
            {
                "x": 1539129600000,
                "y": 28.45
            },
            {
                "x": 1539216000000,
                "y": 28
            },
            {
                "x": 1539302400000,
                "y": 25.63
            },
            {
                "x": 1539388800000,
                "y": 27.2
            },
            {
                "x": 1539475200000,
                "y": 26.86
            },
            {
                "x": 1539561600000,
                "y": 26.63
            },
            {
                "x": 1539648000000,
                "y": 27.27
            },
            {
                "x": 1539734400000,
                "y": 27.46
            },
            {
                "x": 1539820800000,
                "y": 27.79
            },
            {
                "x": 1539907200000,
                "y": 27.71
            },
            {
                "x": 1539993600000,
                "y": 28.21
            },
            {
                "x": 1540080000000,
                "y": 28.22
            },
            {
                "x": 1540166400000,
                "y": 28.48
            },
            {
                "x": 1540252800000,
                "y": 28.43
            },
            {
                "x": 1540339200000,
                "y": 26.84
            },
            {
                "x": 1540425600000,
                "y": 26.11
            },
            {
                "x": 1540512000000,
                "y": 26.89
            },
            {
                "x": 1540598400000,
                "y": 27.07
            },
            {
                "x": 1540684800000,
                "y": 27.01
            },
            {
                "x": 1540857600000,
                "y": 19.11
            },
            {
                "x": 1540944000000,
                "y": 23.93
            },
            {
                "x": 1541030400000,
                "y": 24.63
            },
            {
                "x": 1541116800000,
                "y": 25.76
            },
            {
                "x": 1541203200000,
                "y": 26.68
            },
            {
                "x": 1541289600000,
                "y": 27.12
            },
            {
                "x": 1541376000000,
                "y": 26.05
            },
            {
                "x": 1541462400000,
                "y": 25.92
            },
            {
                "x": 1541548800000,
                "y": 25.24
            },
            {
                "x": 1541635200000,
                "y": 24.92
            },
            {
                "x": 1541721600000,
                "y": 25.33
            },
            {
                "x": 1541808000000,
                "y": 24.9
            },
            {
                "x": 1541894400000,
                "y": 24.33
            },
            {
                "x": 1541980800000,
                "y": 23.45
            },
            {
                "x": 1542067200000,
                "y": 23.6
            },
            {
                "x": 1542153600000,
                "y": 24.03
            },
            {
                "x": 1542240000000,
                "y": 23.88
            },
            {
                "x": 1542326400000,
                "y": 23.19
            },
            {
                "x": 1542412800000,
                "y": 24.05
            },
            {
                "x": 1542499200000,
                "y": 25.93
            },
            {
                "x": 1542585600000,
                "y": 26.01
            },
            {
                "x": 1542672000000,
                "y": 25
            },
            {
                "x": 1542758400000,
                "y": 25.38
            },
            {
                "x": 1542844800000,
                "y": 25.49
            },
            {
                "x": 1542931200000,
                "y": 24.25
            },
            {
                "x": 1543017600000,
                "y": 23.36
            },
            {
                "x": 1543104000000,
                "y": 23.38
            },
            {
                "x": 1543190400000,
                "y": 22.94
            },
            {
                "x": 1543449600000,
                "y": 21.67
            },
            {
                "x": 1543536000000,
                "y": 21.37
            },
            {
                "x": 1543622400000,
                "y": 20.88
            },
            {
                "x": 1543708800000,
                "y": 21.21
            },
            {
                "x": 1543795200000,
                "y": 22.26
            },
            {
                "x": 1543881600000,
                "y": 23.14
            },
            {
                "x": 1543968000000,
                "y": 23.58
            },
            {
                "x": 1544054400000,
                "y": 24.19
            },
            {
                "x": 1544140800000,
                "y": 25.07
            },
            {
                "x": 1544227200000,
                "y": 25.11
            },
            {
                "x": 1544313600000,
                "y": 24.17
            },
            {
                "x": 1544400000000,
                "y": 22.22
            },
            {
                "x": 1544486400000,
                "y": 18.63
            },
            {
                "x": 1544572800000,
                "y": 20.48
            },
            {
                "x": 1544659200000,
                "y": 19.99
            },
            {
                "x": 1544745600000,
                "y": 20.25
            },
            {
                "x": 1544832000000,
                "y": 21.05
            },
            {
                "x": 1544918400000,
                "y": 19.72
            },
            {
                "x": 1545004800000,
                "y": 18.79
            },
            {
                "x": 1545091200000,
                "y": 19.14
            },
            {
                "x": 1545177600000,
                "y": 18.04
            },
            {
                "x": 1545264000000,
                "y": 18.63
            },
            {
                "x": 1545350400000,
                "y": 20.25
            },
            {
                "x": 1545436800000,
                "y": 21.45
            },
            {
                "x": 1545523200000,
                "y": 22.53
            },
            {
                "x": 1545609600000,
                "y": 23.89
            },
            {
                "x": 1545696000000,
                "y": 24.81
            },
            {
                "x": 1545782400000,
                "y": 22.73
            },
            {
                "x": 1545868800000,
                "y": 18.27
            },
            {
                "x": 1545955200000,
                "y": 16.29
            },
            {
                "x": 1546041600000,
                "y": 16.39
            },
            {
                "x": 1546128000000,
                "y": 16.75
            },
            {
                "x": 1546214400000,
                "y": 17.55
            },
            {
                "x": 1546300800000,
                "y": 18.79
            },
            {
                "x": 1546387200000,
                "y": 20.23
            },
            {
                "x": 1546473600000,
                "y": 20.91
            },
            {
                "x": 1546560000000,
                "y": 17.3
            },
            {
                "x": 1546646400000,
                "y": 20.63
            },
            {
                "x": 1546732800000,
                "y": 21.06
            },
            {
                "x": 1546819200000,
                "y": 19.42
            },
            {
                "x": 1546905600000,
                "y": 18.31
            },
            {
                "x": 1546992000000,
                "y": 19.97
            },
            {
                "x": 1547078400000,
                "y": 20.24
            },
            {
                "x": 1547164800000,
                "y": 21.6
            },
            {
                "x": 1547251200000,
                "y": 22.16
            },
            {
                "x": 1547337600000,
                "y": 21.42
            },
            {
                "x": 1547424000000,
                "y": 21.01
            },
            {
                "x": 1547510400000,
                "y": 20.92
            }
        ],
        "marker": {
            "enabled": false
        },
        "tooltip": {
            "valueSuffix": " °C",
            "valueDecimals": 2
        },
        "id": "cc-2"
    };
    var avg_rh_ts = {
        "name": "Avg Relative Humidity",
        "color": "#CC834A",
        "type": "spline",
        "data": [
            {
                "x": 1530403200000,
                "y": 62.33
            },
            {
                "x": 1530489600000,
                "y": 66.5
            },
            {
                "x": 1530576000000,
                "y": 70.08
            },
            {
                "x": 1530662400000,
                "y": 74
            },
            {
                "x": 1530748800000,
                "y": 74.79
            },
            {
                "x": 1530835200000,
                "y": 71.08
            },
            {
                "x": 1530921600000,
                "y": 75.17
            },
            {
                "x": 1531008000000,
                "y": 76.96
            },
            {
                "x": 1531094400000,
                "y": 78.29
            },
            {
                "x": 1531180800000,
                "y": 77
            },
            {
                "x": 1531267200000,
                "y": 80.04
            },
            {
                "x": 1531353600000,
                "y": 83.13
            },
            {
                "x": 1531440000000,
                "y": 76.58
            },
            {
                "x": 1531526400000,
                "y": 77.46
            },
            {
                "x": 1531612800000,
                "y": 83
            },
            {
                "x": 1531699200000,
                "y": 86.63
            },
            {
                "x": 1531785600000,
                "y": 85.38
            },
            {
                "x": 1531872000000,
                "y": 79.63
            },
            {
                "x": 1531958400000,
                "y": 77.67
            },
            {
                "x": 1532044800000,
                "y": 79.17
            },
            {
                "x": 1532131200000,
                "y": 76.29
            },
            {
                "x": 1532217600000,
                "y": 79.92
            },
            {
                "x": 1532304000000,
                "y": 79.09
            },
            {
                "x": 1532390400000,
                "y": 78.71
            },
            {
                "x": 1532476800000,
                "y": 75.08
            },
            {
                "x": 1532563200000,
                "y": 72.92
            },
            {
                "x": 1532649600000,
                "y": 72.14
            },
            {
                "x": 1532736000000,
                "y": 68.35
            },
            {
                "x": 1532822400000,
                "y": 67.08
            },
            {
                "x": 1532908800000,
                "y": 67.54
            },
            {
                "x": 1532995200000,
                "y": 68.33
            },
            {
                "x": 1533081600000,
                "y": 70.13
            },
            {
                "x": 1533168000000,
                "y": 68.7
            },
            {
                "x": 1533254400000,
                "y": 67.92
            },
            {
                "x": 1533340800000,
                "y": 70.79
            },
            {
                "x": 1533427200000,
                "y": 69.75
            },
            {
                "x": 1533513600000,
                "y": 69.08
            },
            {
                "x": 1533600000000,
                "y": 67.38
            },
            {
                "x": 1533686400000,
                "y": 70.92
            },
            {
                "x": 1533772800000,
                "y": 73.92
            },
            {
                "x": 1533859200000,
                "y": 68.54
            },
            {
                "x": 1533945600000,
                "y": 72
            },
            {
                "x": 1534032000000,
                "y": 73.83
            },
            {
                "x": 1534204800000,
                "y": 75.89
            },
            {
                "x": 1534291200000,
                "y": 78.65
            },
            {
                "x": 1534377600000,
                "y": 89.06
            },
            {
                "x": 1534464000000,
                "y": 87.96
            },
            {
                "x": 1534550400000,
                "y": 80.88
            },
            {
                "x": 1534636800000,
                "y": 78.21
            },
            {
                "x": 1534723200000,
                "y": 82.58
            },
            {
                "x": 1534809600000,
                "y": 89.38
            },
            {
                "x": 1534896000000,
                "y": 83.04
            },
            {
                "x": 1534982400000,
                "y": 79.87
            },
            {
                "x": 1535068800000,
                "y": 76.63
            },
            {
                "x": 1535155200000,
                "y": 76.5
            },
            {
                "x": 1535241600000,
                "y": 74.42
            },
            {
                "x": 1535328000000,
                "y": 76.71
            },
            {
                "x": 1535414400000,
                "y": 74.58
            },
            {
                "x": 1535500800000,
                "y": 71.67
            },
            {
                "x": 1535587200000,
                "y": 69.17
            },
            {
                "x": 1535673600000,
                "y": 70.38
            },
            {
                "x": 1535760000000,
                "y": 70.71
            },
            {
                "x": 1535846400000,
                "y": 70.04
            },
            {
                "x": 1535932800000,
                "y": 68.96
            },
            {
                "x": 1536019200000,
                "y": 71.71
            },
            {
                "x": 1536105600000,
                "y": 69.5
            },
            {
                "x": 1536192000000,
                "y": 68.46
            },
            {
                "x": 1536278400000,
                "y": 66.63
            },
            {
                "x": 1536364800000,
                "y": 65.57
            },
            {
                "x": 1536451200000,
                "y": 62.7
            },
            {
                "x": 1536537600000,
                "y": 69.83
            },
            {
                "x": 1536624000000,
                "y": 67.71
            },
            {
                "x": 1536710400000,
                "y": 62.29
            },
            {
                "x": 1536796800000,
                "y": 63.63
            },
            {
                "x": 1536883200000,
                "y": 65.04
            },
            {
                "x": 1536969600000,
                "y": 67.83
            },
            {
                "x": 1537056000000,
                "y": 73.46
            },
            {
                "x": 1537142400000,
                "y": 66.58
            },
            {
                "x": 1537228800000,
                "y": 70.29
            },
            {
                "x": 1537315200000,
                "y": 70
            },
            {
                "x": 1537401600000,
                "y": 68.67
            },
            {
                "x": 1537488000000,
                "y": 63.83
            },
            {
                "x": 1537574400000,
                "y": 69.08
            },
            {
                "x": 1537660800000,
                "y": 67.79
            },
            {
                "x": 1537747200000,
                "y": 64.42
            },
            {
                "x": 1537833600000,
                "y": 60.88
            },
            {
                "x": 1537920000000,
                "y": 63.42
            },
            {
                "x": 1538006400000,
                "y": 61.88
            },
            {
                "x": 1538092800000,
                "y": 61.54
            },
            {
                "x": 1538179200000,
                "y": 54.63
            },
            {
                "x": 1538265600000,
                "y": 53.67
            },
            {
                "x": 1538352000000,
                "y": 55.25
            },
            {
                "x": 1538438400000,
                "y": 57.13
            },
            {
                "x": 1538524800000,
                "y": 54.71
            },
            {
                "x": 1538611200000,
                "y": 57.38
            },
            {
                "x": 1538697600000,
                "y": 59.38
            },
            {
                "x": 1538784000000,
                "y": 54.83
            },
            {
                "x": 1538870400000,
                "y": 49.13
            },
            {
                "x": 1538956800000,
                "y": 46.42
            },
            {
                "x": 1539043200000,
                "y": 40.5
            },
            {
                "x": 1539129600000,
                "y": 38.21
            },
            {
                "x": 1539216000000,
                "y": 42.75
            },
            {
                "x": 1539302400000,
                "y": 50.71
            },
            {
                "x": 1539388800000,
                "y": 42.25
            },
            {
                "x": 1539475200000,
                "y": 43.38
            },
            {
                "x": 1539561600000,
                "y": 40.58
            },
            {
                "x": 1539648000000,
                "y": 40.25
            },
            {
                "x": 1539734400000,
                "y": 43.29
            },
            {
                "x": 1539820800000,
                "y": 50.29
            },
            {
                "x": 1539907200000,
                "y": 46.92
            },
            {
                "x": 1539993600000,
                "y": 41.33
            },
            {
                "x": 1540080000000,
                "y": 42.58
            },
            {
                "x": 1540166400000,
                "y": 41.5
            },
            {
                "x": 1540252800000,
                "y": 32.88
            },
            {
                "x": 1540339200000,
                "y": 32.06
            },
            {
                "x": 1540425600000,
                "y": 46.17
            },
            {
                "x": 1540512000000,
                "y": 40.33
            },
            {
                "x": 1540598400000,
                "y": 33.13
            },
            {
                "x": 1540684800000,
                "y": 32.38
            },
            {
                "x": 1540857600000,
                "y": 51.2
            },
            {
                "x": 1540944000000,
                "y": 43.37
            },
            {
                "x": 1541030400000,
                "y": 51.79
            },
            {
                "x": 1541116800000,
                "y": 51.39
            },
            {
                "x": 1541203200000,
                "y": 55.67
            },
            {
                "x": 1541289600000,
                "y": 60.04
            },
            {
                "x": 1541376000000,
                "y": 67.17
            },
            {
                "x": 1541462400000,
                "y": 60.58
            },
            {
                "x": 1541548800000,
                "y": 46.38
            },
            {
                "x": 1541635200000,
                "y": 46.75
            },
            {
                "x": 1541721600000,
                "y": 49.21
            },
            {
                "x": 1541808000000,
                "y": 45.92
            },
            {
                "x": 1541894400000,
                "y": 40.21
            },
            {
                "x": 1541980800000,
                "y": 37
            },
            {
                "x": 1542067200000,
                "y": 36.08
            },
            {
                "x": 1542153600000,
                "y": 37.17
            },
            {
                "x": 1542240000000,
                "y": 35.88
            },
            {
                "x": 1542326400000,
                "y": 39.46
            },
            {
                "x": 1542412800000,
                "y": 40.83
            },
            {
                "x": 1542499200000,
                "y": 47.5
            },
            {
                "x": 1542585600000,
                "y": 60.13
            },
            {
                "x": 1542672000000,
                "y": 67.29
            },
            {
                "x": 1542758400000,
                "y": 58.79
            },
            {
                "x": 1542844800000,
                "y": 46.96
            },
            {
                "x": 1542931200000,
                "y": 43.21
            },
            {
                "x": 1543017600000,
                "y": 45.54
            },
            {
                "x": 1543104000000,
                "y": 47.33
            },
            {
                "x": 1543190400000,
                "y": 45.42
            },
            {
                "x": 1543449600000,
                "y": 44.58
            },
            {
                "x": 1543536000000,
                "y": 45.04
            },
            {
                "x": 1543622400000,
                "y": 47.08
            },
            {
                "x": 1543708800000,
                "y": 51.42
            },
            {
                "x": 1543795200000,
                "y": 50.38
            },
            {
                "x": 1543881600000,
                "y": 51.5
            },
            {
                "x": 1543968000000,
                "y": 51.96
            },
            {
                "x": 1544054400000,
                "y": 49.75
            },
            {
                "x": 1544140800000,
                "y": 44.13
            },
            {
                "x": 1544227200000,
                "y": 46.13
            },
            {
                "x": 1544313600000,
                "y": 44.33
            },
            {
                "x": 1544400000000,
                "y": 36.79
            },
            {
                "x": 1544486400000,
                "y": 41.2
            },
            {
                "x": 1544572800000,
                "y": 40.5
            },
            {
                "x": 1544659200000,
                "y": 47.24
            },
            {
                "x": 1544745600000,
                "y": 50.25
            },
            {
                "x": 1544832000000,
                "y": 48.08
            },
            {
                "x": 1544918400000,
                "y": 46.83
            },
            {
                "x": 1545004800000,
                "y": 49.75
            },
            {
                "x": 1545091200000,
                "y": 55.79
            },
            {
                "x": 1545177600000,
                "y": 48.29
            },
            {
                "x": 1545264000000,
                "y": 50.63
            },
            {
                "x": 1545350400000,
                "y": 53.33
            },
            {
                "x": 1545436800000,
                "y": 45.88
            },
            {
                "x": 1545523200000,
                "y": 47.08
            },
            {
                "x": 1545609600000,
                "y": 51.58
            },
            {
                "x": 1545696000000,
                "y": 44.71
            },
            {
                "x": 1545782400000,
                "y": 43.83
            },
            {
                "x": 1545868800000,
                "y": 32.92
            },
            {
                "x": 1545955200000,
                "y": 36.33
            },
            {
                "x": 1546041600000,
                "y": 35.42
            },
            {
                "x": 1546128000000,
                "y": 33.21
            },
            {
                "x": 1546214400000,
                "y": 34.29
            },
            {
                "x": 1546300800000,
                "y": 30.79
            },
            {
                "x": 1546387200000,
                "y": 30.33
            },
            {
                "x": 1546473600000,
                "y": 32.92
            },
            {
                "x": 1546560000000,
                "y": 41
            },
            {
                "x": 1546646400000,
                "y": 40.38
            },
            {
                "x": 1546732800000,
                "y": 38.17
            },
            {
                "x": 1546819200000,
                "y": 39.05
            },
            {
                "x": 1546905600000,
                "y": 34.25
            },
            {
                "x": 1546992000000,
                "y": 30.54
            },
            {
                "x": 1547078400000,
                "y": 32.38
            },
            {
                "x": 1547164800000,
                "y": 34.5
            },
            {
                "x": 1547251200000,
                "y": 34.58
            },
            {
                "x": 1547337600000,
                "y": 36.5
            },
            {
                "x": 1547424000000,
                "y": 42.17
            },
            {
                "x": 1547510400000,
                "y": 47.92
            }
        ],
        "marker": {
            "enabled": false
        },
        "tooltip": {
            "valueSuffix": " %",
            "valueDecimals": 2
        },
        "yAxis": 2,
        "id": "cc-3"
    };

    return {
        getRainfall: function () {
            return total_rainfall_ts;
        },
        getTemperature: function () {
            return avg_temperature_ts;
        },
        getRH: function () {
            return avg_rh_ts;
        }
    };
});
