angular.module('QoipApp').component('qoipModelOutput', {
    templateUrl: '/static/assets/khapps/quakeroats/model_output/qoip-model-output.component.html',
    controller: function (QoipModelParameterListFactory, qoipPlotSelectorService) {
        var self = this;
        self.active_field = null;
        self.model_parameters = QoipModelParameterListFactory.model_parameters;
        self.growth_stage_data
        self.selectedParameter = self.model_parameters[0];
        self.yield_chart = {};
        self.biomass_chart = {};
        self.sub = qoipPlotSelectorService.field$().subscribe(function() {
            if (qoipPlotSelectorService.value()) {
                self.active_field = qoipPlotSelectorService.value();
                setGrowthStageData();
                self.biomass_chart = createChartConfig('Biomass', 'Biomass t/ha', 'Biomass');
                self.yield_chart = createChartConfig('Yield', 'Yield t/ha', 'Yield');
            }
        });

        function setGrowthStageData() {
            self.growth_stage_data = {
                columns: [
                    'Growth Stage',
                    'Date'

                ],
                data: _.map(
                    self.active_field.model_data.growth_stage,
                    function(obj){
                        return {
                            'growth_stage': obj.growth_stage,
                            'date_low': obj.low_date,
                            'date_high': obj.high_date
                        }
                })
            };
        };

        function createChartConfig(title, yaxis_title, model_parameter) {
            if (model_parameter === 'Biomass') {
                return {
                    title: {
                        text: null

                    },

                    xAxis: {
                        type: 'linear',
                        tickPositions: [39, 59, 65, 71, 87],
                    },

                    yAxis: {
                        title: {
                            text: yaxis_title
                        }
                    },

                    series: [{
                        showInLegend: true,
                        name: 'Field (' + self.active_field.name + ')',
                        data: self.active_field.model_data.biomass.grower,
                        zIndex: 2
                    },
                    {
                        showInLegend: true,
                        name: 'Growth Guide Benchmark',
                        data: self.active_field.model_data.biomass.benchmark,
                        zIndex: 1,
                        dashStyle: 'longdashdot',
                        color: '#c00'
                    }]
                };
            }

            if (model_parameter === 'Yield') {
                return {
                    title: {
                        text: null
                    },

                    yAxis: {
                        title: {
                            text: yaxis_title
                        }
                    },
                    xAxis: {
                        labels: {
                            enabled: false
                        }
                    },
                    series: [{
                        type: 'column',
                        name: 'Yield',
                        data: [self.active_field.model_data.yield.grower]
                            
                    },{
                        type: 'column',
                        name: 'Trial Min',
                        data: [self.active_field.model_data.yield.aggregates.min]
                            
                    },
                    {
                        type: 'column',
                        name: 'Trial Max',
                        data: [self.active_field.model_data.yield.aggregates.max]
                            
                    },
                    {
                        type: 'column',
                        name: 'Trial Avg',
                        data: [self.active_field.model_data.yield.aggregates.avg]
                            
                    }]
                };
            }
        }
    }
});
