angular.module('QoipApp').config(
    function ($routeProvider, $resourceProvider, $translateProvider) {
        'use strict';

        // TODO: what is this?
        var qoip_en = {
            action: 'Action',
            created_successfully: 'Created successfully',
            download_user_guide: 'Download the user guide'
        };

        $translateProvider.translations('en', qoip_en);
        $resourceProvider.defaults.stripTrailingSlashes = false;

        $routeProvider
            .when('/', {
                template: '<qoip-landing></qoip-landing>'
            })
            .otherwise({
                redirectTo: '/'
            });

    });
