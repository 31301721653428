angular.module('QoipApp').factory('QoipGrowerPlotListFactory', function ($resource){
    'use strict';
    return {
        fields : function(){
            return $resource('/api/qoip/field/').get().$promise;
        },
        field : function(field_id){
            return $resource('/api/qoip/field/:field_id/').get(
                {'field_id': field_id}
            ).$promise;
        }
    };
});
