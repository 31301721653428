angular.module('QoipApp').factory('QoipCultivationPracticesFactory', function ($q) {

    var cultivationPractices = [
        {name: 'Direct drill', value: 'direct_drill'},
        {name: 'Minimum Tillage', value: 'minimum_tillage'},
        {name: 'Inversion', value: 'inversion'},
    ];
    return {
        query: function () {
            return $q(function (resolve) {
                resolve(cultivationPractices);
            });
        }
    };
});

