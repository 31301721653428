angular.module('QoipApp').factory('QoipModelParameterListFactory', function (){

    return {
        model_parameters: [
            {name: 'Biomass', value: 'biomass-slug', unit: 't/ha'},
            {name: 'Yield', value: 'yield-slug', unit: 't/ha'}
        ]
    };
});

