angular.module('QoipApp').component('qoipFieldSetupInput', {
    templateUrl: '/static/assets/khapps/quakeroats/field_setup_input/field-setup-input.component.html',
    controller: function ($q, $resource, $khMessage, QoipNitrogenIndexFactory, 
                          QoipCultivationPracticesFactory, QoipOrganicAmmendmentsFactory, 
                          qoipPlotSelectorService) {
        var self = this;
        self.setup_data = $resource(
            '/api/qoip/field/:field_id/setup-data/',
            {field_id: '@field_id'}
        );
        self.selectedData = {};
        self.active_field = null;
        self.saving = false;
        self.sub = qoipPlotSelectorService.field$().subscribe(function() {
            if (qoipPlotSelectorService.value()) {
                self.active_field = qoipPlotSelectorService.value();
                self.selectedData = {
                    sowing_date: self.active_field.setup_data.sowing_date,
                    nregime: self.active_field.setup_data.nitrogen_regime,
                    nindex: self.active_field.setup_data.nitrogen_index,
                    cultivation_practice: self.active_field.setup_data.cultivation_practice,
                    water_capacity: self.active_field.setup_data.water_capacity,
                    organic_amendment1: '',
                    organic_amendment2: '',
                    organic_amendment3: ''
                };
            }
        });
        self.$onInit = function () {
            $q.all([
                QoipNitrogenIndexFactory.query(),
                QoipCultivationPracticesFactory.query(),
                QoipOrganicAmmendmentsFactory.query()]).then(function (results) {
                self.soilNIndexes = results[0];
                self.cultivationPractices = results[1];
                self.organicAmendments = results[2];
            });
        };
        self.save = function() {
            var data = {
                field_id: self.active_field.id,
                nitrogen_regime: self.selectedData.nregime,
                nitrogen_index: self.selectedData.nindex,
                cultivation_practice: self.selectedData.cultivation_practice,
                sowing_date: self.selectedData.sowing_date,
                water_capacity: self.selectedData.water_capacity
            };
            self.saving = true;
            self.setup_data.save(data).$promise.then(
                function() {
                    $khMessage.show(
                        'Field configuration has been updated successfully.'
                    );
                    self.saving = false;
                },
                function(error){
                    self.is_loading = false;
                    $khMessage.show('Error. Setup data could not be saved.');
                }
            );
        };
    }

});
