angular.module('QoipApp').factory('QoipNitrogenIndexFactory', function ($q) {
    //Soil Nitrogen Supply Index (0-6 Index)
    //http://adlib.everysite.co.uk/adlib/defra/content.aspx?id=2RRVTHNXTS.88VIP7J3HI1OO
    var nitrogenIndexes = [
        {name: 0, nitrogenIndex: 0, value: '<60', unit: 'kgN/ha'},
        {name: 1, nitrogenIndex: 1, value: '61-80', unit: 'kgN/ha'},
        {name: 2, nitrogenIndex: 2, value: '81-100', unit: 'kgN/ha'},
        {name: 3, nitrogenIndex: 3, value: '101-120', unit: 'kgN/ha'},
        {name: 4, nitrogenIndex: 4, value: '121-160', unit: 'kgN/ha'},
        {name: 5, nitrogenIndex: 5, value: '161-240', unit: 'kgN/ha'},
        {name: 6, nitrogenIndex: 6, value: '>240', unit: 'kgN/ha'},
    ];
    return {
        query: function () {
            return $q(function (resolve) {
                resolve(nitrogenIndexes);
            });
        }
    };
});

