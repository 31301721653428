angular.module('QoipApp').service("qoipMapService",
    function ($khMessage, $q, leafletData, QoipSoilDataFactory, QoipSatelliteDataFactory) {

        var self = this;

        self.zoom = null;
        self.geoLayers = {};
        self.geodata = {};
        self.map = null;
        self.overlays = null;
        self.selectedFeature = null;
        self.drawOverlayLayer = null;
        self.drawnLayer = null;
        self.mainLayerGroup = null;
        self.selectedMapParameter = null;
        var mapId = 'landingMap';

        /**
         * Dict of feature slug to feature layer
         */
        var feature_slug_to_layer = {};

        /**
         * Zoom to a bounded feature given an event
         * @param event - target event
         */
        function zoomToBoundedFeature(event) {
            self.map.fitBounds(event.target.getBounds(), {padding: [100, 100]});
        }

        function zoomToFeature(event) {
            self.map.fitBounds(event.target.getBounds(), {padding: [100, 100]});
        }

        /**
         * Zoom to the bounding box of a feature
         * @param filter_field_feature
         */
        function zoomToBoundingBox(filter_field_feature) {
            if (filter_field_feature) {
                var bounds = filter_field_feature.getBounds();
                if (angular.isDefined(bounds)) {
                    self.map.fitBounds(bounds);
                }
            }
        }

        function drawFieldCropLayer(data) {
            var getStyle = function (feature) {
                var fillcolor = "orange";
                return {
                    color: fillcolor,
                    fillColor: fillcolor,
                    weight: 1,
                    opacity: 0.5,
                    fillOpacity: 0.1
                };
            };

            function onEachFeature(feature, layer) {
                feature.properties.bounds_calculated = layer.getBounds().toBBoxString();
                layer.on({click: zoomToBoundedFeature});
                feature_slug_to_layer[feature.properties.plot_slug] = layer;
            }

            return L.geoJson(data, {
                layer: 'FieldCropLayer',
                onEachFeature: onEachFeature,
                style: getStyle
            });
        }

        function drawFieldSoilLayer(data) {
            var getStyle = function (feature) {
                var display_value = self.selectedMapParameter.value;
                var fillcolor = "white";
                if (feature.properties.hasOwnProperty(display_value)) {
                    switch (display_value) {
                        case 'pH':
                            data_value = feature.properties[display_value];
                            switch (true) {
                                case (data_value <= 5.25):
                                    fillcolor = "#ca0020";
                                    break;
                                case (data_value <= 5.5):
                                    fillcolor = "#dd494b";
                                    break;
                                case (data_value <= 5.75):
                                    fillcolor = "#f09377";
                                    break;
                                case (data_value <= 6):
                                    fillcolor = "#f5c1a9";
                                    break;
                                case (data_value <= 6.25):
                                    fillcolor = "#f7e5dd";
                                    break;
                                case (data_value <= 6.5):
                                    fillcolor = "#e1ecf2";
                                    break;
                            }
                            break;
                        case 'P_Inx':
                            data_value = feature.properties[display_value];
                            switch (data_value) {
                                case '1':
                                    fillcolor = "#ff7f00";
                                    break;
                                case '1+':
                                    fillcolor = "#fdf108";
                                    break;
                                case '2-':
                                    fillcolor = "#5abe73";
                                    break;
                                case '2':
                                    fillcolor = "#74f595";
                                    break;
                                case '2+':
                                    fillcolor = "#367345";
                                    break;
                                case '3-':
                                    fillcolor = "#21472b";
                                    break;
                            }
                            break;
                        case 'K_Inx':
                            data_value = feature.properties[display_value];
                            switch (data_value) {
                                case '1':
                                    fillcolor = "#ff7f00";
                                    break;
                                case '1+':
                                    fillcolor = "#fdf108";
                                    break;
                                case '2-':
                                    fillcolor = "#5abe73";
                                    break;
                                case '2':
                                    fillcolor = "#74f595";
                                    break;
                                case '2+':
                                    fillcolor = "#367345";
                                    break;
                                case '3-':
                                    fillcolor = "#21472b";
                                    break;
                            }
                            break;
                        case 'Mg_Inx':
                            data_value = feature.properties[display_value];
                            switch (data_value) {
                                case '1':
                                    fillcolor = "#ff7f00";
                                    break;
                                case '1+':
                                    fillcolor = "#fdf108";
                                    break;
                                case '2-':
                                    fillcolor = "#5abe73";
                                    break;
                                case '2':
                                    fillcolor = "#74f595";
                                    break;
                                case '2+':
                                    fillcolor = "#367345";
                                    break;
                                case '3-':
                                    fillcolor = "#21472b";
                                    break;
                            }
                            break;
                        case 'Elevation_':
                            data_value = feature.properties[display_value];
                            switch (true) {
                                case (data_value <= 13):
                                    fillcolor = "#ca0020";
                                    break;
                                case (data_value <= 16):
                                    fillcolor = "#dd494b";
                                    break;
                                case (data_value <= 18):
                                    fillcolor = "#f09377";
                                    break;
                                case (data_value <= 20):
                                    fillcolor = "#f5c1a9";
                                    break;
                                case (data_value <= 23):
                                    fillcolor = "#f7e5dd";
                                    break;
                                case (data_value <= 25):
                                    fillcolor = "#e1ecf2";
                                    break;
                                case (data_value <= 28):
                                    fillcolor = "#b4d6e7";
                                    break;
                                case (data_value <= 30):
                                    fillcolor = "#82bcd9";
                                    break;
                                case (data_value <= 32):
                                    fillcolor = "#4396c5";
                                    break;
                                case (data_value <= 35):
                                    fillcolor = "#0571b0";
                                    break;
                                default:
                                    fillcolor = "#ffffff";
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                }
                return {
                    color: fillcolor,
                    fillColor: fillcolor,
                    weight: 0,
                    opacity: 1,
                    fillOpacity: 1
                };
            };

            function onEachFeature(feature, layer) {
                layer.on({click: zoomToFeature});
                feature_slug_to_layer[feature.properties.plot_slug] = layer;
            }

            return L.geoJson(data, {
                layer: 'FieldSoilLayer',
                onEachFeature: onEachFeature,
                style: getStyle
            });
        }


        function drawSatelliteLayer(data) {
            var bounds = new L.LatLngBounds(
                new L.LatLng(data.satellitelayer.bounds.top_left_lat, data.satellitelayer.bounds.top_left_lon),
                new L.LatLng(data.satellitelayer.bounds.bottom_right_lat, data.satellitelayer.bounds.bottom_right_lon)
            );
            var options = {
                layerParams: {
                    showOnSelector: true,
                    attribution: 'KisanSat'
                }
            };
            return L.imageOverlay(data.satellitelayer.url, bounds, options);
        }

        /**
         * Remove all layers
         */
        function removeLayers() {
            if (self.mainLayerGroup) {
                self.mainLayerGroup.clearLayers();
            }
            self.geoLayers = {};
            self.drawOverlayLayer = null;
            self.drawnLayer = null;
        }

        function initiate_map(map_parameter) {
            if (self.map) {
                self.map = null;
            }
            self.loading = true;
            self.selectedMapParameter = map_parameter;
        }

        function setting_map_properties(map) {
            self.map = map;
            self.geodata = self.result;
            self.loading = false;

            if (self.mainLayerGroup) {
                removeLayers();
            }

            self.mainLayerGroup = L.layerGroup().addTo(map);

            map.off('draw:created');
        }

        this.reload = function (field) {
            initiate_map(null);
            createFieldLayer(field.features.field);

            function createFieldLayer(data) {
                return leafletData.getMap(mapId).then(function (map) {
                    setting_map_properties(map);
                    var layer = self.geoLayers['fieldcrop'] = drawFieldCropLayer(data);
                    self.mainLayerGroup.addLayer(layer);
                    layer.bringToFront();

                    if (!self.zoom) {
                        self.center = {
                            lat: field.latitude,
                            lng: field.longitude
                        };
                        self.zoom = 15;
                        self.map.setView(self.center, self.zoom);
                    } else {
                        zoomToBoundingBox(self.geoLayers.fieldcrop);
                    }
                });
            }
        };

        this.loadSoilLayer = function (field, map_parameter) {
            initiate_map(map_parameter);

            QoipSoilDataFactory.get().$promise
                .then(function (response) {
                    return createSoilLayer(response.data);
                })
                // Catch any error...
                .catch(function (error) {
                    $khMessage.show(error, 5000);
                });

            function createSoilLayer(data) {
                return leafletData.getMap(mapId).then(function (map) {
                    setting_map_properties(map);
                    if (data) {
                        var layer = self.geoLayers['soillayer'] = drawFieldSoilLayer(data);
                        self.mainLayerGroup.addLayer(layer);
                        layer.bringToFront();
                        zoomToBoundingBox(self.geoLayers.soillayer);
                    } else {
                        self.reload(field);
                    }
                });
            }
        };

        this.loadSatelliteLayer = function (field, map_parameter) {
            initiate_map(map_parameter);
            var satelliteData = null;
            var plotData = null;

            $q.all(
                [QoipSatelliteDataFactory.get(field.value)]
            ).then(function (results) {
                    satelliteData = results[0];
                    plotData = results[1];
                    return createSatelliteLayer(satelliteData, field);
                }
            ).catch(function (error) {
                    $khMessage.show(error, 5000);
                }
             );

            function createSatelliteLayer(satData, plotData) {
                return leafletData.getMap(mapId).then(function (map) {
                    var layer;
                    setting_map_properties(map);
                    if (satData) {
                        layer = self.geoLayers['satellitelayer'] = drawSatelliteLayer(satData);
                        self.mainLayerGroup.addLayer(layer);
                        layer.bringToBack();
                    }
                    layer = self.geoLayers['fieldcrop'] = drawFieldCropLayer(plotData['fieldcrop']);
                    self.mainLayerGroup.addLayer(layer);
                    layer.bringToFront();
                    zoomToBoundingBox(self.geoLayers.fieldcrop);
                });
            }
        };

    });
