angular.module('QoipApp').component('qoipLanding', {
    templateUrl: '/static/assets/khapps/quakeroats/landing/qoip-landing.component.html',
    controller: function ($scope, $rootScope, qoipPlotSelectorService, QoipGrowerPlotListFactory) {
        'use strict';

        const self = this;
        self.field_summaries = []; // list of field summaries
        self.field_summary = null; // the active field summary
        self.field = null; // the full details of the active field

        $rootScope.page_id = 'qoip';
        $rootScope.pageTitle = "Quaker Oat Dashboard";
        $rootScope.sub_app_page_id = null;
        $rootScope.currentNavItem = null;

        self.$onInit = function() {
            self.field_listener = qoipPlotSelectorService.field$().subscribe(
                function() {
                    if(qoipPlotSelectorService.value()) {
                        self.field = null;
                        self.field = qoipPlotSelectorService.value();
                    }
                }
            );
            QoipGrowerPlotListFactory.fields().then(
                function(result) {
                    self.field_summaries = result.data;
                    self.field_summary = self.field_summaries[0];
                    self.setField();
                }
            );
        };

        self.setField = function() {
            qoipPlotSelectorService.setField(self.field_summary);
        };

        self.pageChanged = function(page, pageSize) {
            self.page = page;
            self.pageSize = pageSize;
        };

        self.totalChanged = function (total) {
            self.total = total;
        };

        self.$onDestroy = function () {
            self.field_listener.destroy();
        };
    }
});
