angular.module('QoipApp').component('qoipWeather', {
    templateUrl: '/static/assets/khapps/quakeroats/weather/qoip-weather.component.html',
    controller: function ($resource, $khMessage, QoipFieldWeatherDataFactory, qoipPlotSelectorService) {
        var self = this;
        self.active_field = null;
        self.weather = $resource('/api/qoip/field/:field_id/weather/', {field_id: "@field_id"})
        self.weather_chart = {
            title: {
                text: ''
            },
            exporting: {
                enabled: false
            },
            lang: {
                noData: "No data..."
            },
            chart: {
                zoomType: "xy"
            },
            credits: {
                enabled: false
            },
            rangeSelector: {
                enabled: false
            },
            scrollbar: {
                enabled: false
            },
            navigator: {
                enabled: false,
                adaptToUpdatedData: true
            },
            xAxis: [
                {
                    type: "datetime",
                    labels: {
                        format: "{value: %e %b}"
                    },
                    plotLines: [{
                        color: 'red',
                        dashStyle: 'longdashdot',
                        value: Date.now(),
                        width: 2 
                    }]
                }
            ],
            yAxis: [
                {
                    "labels": {
                        "style": {
                            "color": "#4572A7"
                        }
                    },
                    "title": {
                        "text": "Total Rainfall (mm)",
                        "style": {
                            "color": "#4572A7"
                        }
                    },
                    "opposite": false
                },
                {
                    "title": {
                        "text": "Temperature (°C)",
                        "style": {
                            "color": "#6dbff2"
                        }
                    },
                    "labels": {
                        "style": {
                            "color": "#6dbff2"
                        }
                    },
                    "opposite": true,
                    "type": "arearange"
                },
                {
                    "title": {
                        "text": "Avg Relative Humidity (%)",
                        "style": {
                            "color": "#c113d8"
                        }
                    },
                    "labels": {
                        "style": {
                            "color": "#c113d8"
                        }
                    },
                    "opposite": true
                },
                {
                    "title": {
                        "text": "Solar Radiation (W/m2)",
                        "style": {
                            "color": "#f46d41"
                        }
                    },
                    "labels": {
                        "style": {
                            "color": "#f46d41"
                        }
                    },
                    "opposite": false
                }
            ],
            tooltip: {
                shared: true
            },
            legend: {
                enabled: true,
                align: "center",
                verticalAlign: "top",
                floating: false,
                backgroundColor: "white",
                borderColor: "#CCC",
                borderWidth: 1,
                shadow: false,
                y: -4
            },
            series: [
                {
                    "name": "Total Rainfall (mm)",
                    "color": "#4572A7",
                    "type": "column",
                    "yAxis": 0,
                    "data": [],
                    "marker": {
                        "enabled": false
                    },
                    "tooltip": {
                        "valueSuffix": " (mm)",
                        "valueDecimals": 2
                    }
                },
                {
                    "name": "Temperature (°C)",
                    "type": "arearange",
                    "color": "#6dbff2",
                    "yAxis": 1,
                    "data": [],
                    "marker": {
                        "enabled": false
                    },
                    "tooltip": {
                        "valueSuffix": " °C",
                        "valueDecimals": 2
                    }
                },
                {
                    "name": "Avg Relative Humidity (%)",
                    "color": "#c113d8",
                    "type": "spline",
                    "data": [],
                    "marker": {
                        "enabled": false
                    },
                    "tooltip": {
                        "valueSuffix": " %",
                        "valueDecimals": 2
                    },
                    "yAxis": 2,
                    "visible": false
                },
                {
                    "name": "Solar Radiation (W/m2)",
                    "color": "#f46d41",
                    "type": "spline",
                    "data": [],
                    "marker": {
                        "enabled": false
                    },
                    "tooltip": {
                        "valueSuffix": " W/m2",
                        "valueDecimals": 2
                    },
                    "yAxis": 3
                }
            ]
        };
        self.sub = qoipPlotSelectorService.field$().subscribe(function() {
            self.active_field = qoipPlotSelectorService.value();
            self.weather.get({field_id: self.active_field.id}).$promise.then(
                function(data){
                    var temp = _.map(data['data'], function(obj){
                        return {x: obj.timestamp, low: obj.min_temperature, high: obj.max_temperature}
                    });
                    var humidity = _.map(data['data'], function(obj){
                        return [obj.timestamp, obj.mean_relative_humidity]
                    });
                    var precipitation = _.map(data['data'], function(obj){
                        return [obj.timestamp, obj.total_precipitation]
                    });
                    var radiation = _.map(data['data'], function(obj){
                        return [obj.timestamp, obj.mean_solar_radiation]
                    });
                    buildWeatherChart(temp, humidity, precipitation, radiation);
                },
                function(error){
                  $khMessage.show(
                    'Weather data could not be displayed.');
                  Highcharts.chart('chart_container', {title: {text: ''}});
            });
        });

        //Weather Data Chart Configuration
        function buildWeatherChart(temp, humidity, precipitation, radiation) {
            self.weather_chart.series[0].data = precipitation;
            self.weather_chart.series[1].data = temp;
            self.weather_chart.series[2].data = humidity;
            self.weather_chart.series[3].data = radiation;
            Highcharts.chart('chart_container', self.weather_chart);
        }
    }

});
