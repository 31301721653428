angular.module('QoipApp').factory('QoipOrganicAmmendmentsFactory', function ($q) {

    var organicAmmendments = [
        {name: 'Cover crop', value: 'Cover crop'},
        {name: 'Straw incorporation', value: 'Straw incorporation'},
        {name: 'Manure', value: 'Manure'},
    ];
    return {
        query: function () {
            return $q(function (resolve) {
                resolve(organicAmmendments);
            });
        }
    };
});

