angular.module('QoipApp').factory('QoipMapParameterListFactory', function () {
    var elevation_legend = [
        {value: '0-13', lower_limit: '', upper_limit: '', colour_code: '#ca0020'},
        {value: '13-16', lower_limit: '', upper_limit: '', colour_code: '#dd494b'},
        {value: '16-18', lower_limit: '', upper_limit: '', colour_code: '#f09377'},
        {value: '18-20', lower_limit: '', upper_limit: '', colour_code: '#f5c1a9'},
        {value: '20-23', lower_limit: '', upper_limit: '', colour_code: '#f7e5dd'},
        {value: '23-25', lower_limit: '', upper_limit: '', colour_code: '#e1ecf2'},
        {value: '25-28', lower_limit: '', upper_limit: '', colour_code: '#b4d6e7'},
        {value: '28-30', lower_limit: '', upper_limit: '', colour_code: '#82bcd9'},
        {value: '30-32', lower_limit: '', upper_limit: '', colour_code: '#4396c5'},
        {value: '32-35', lower_limit: '', upper_limit: '', colour_code: '#0571b0'}
    ];

    var pH_legend = [
        {value: '0-5.25', lower_limit: '', upper_limit: '', colour_code: '#ca0020'},
        {value: '5.25-5.5', lower_limit: '', upper_limit: '', colour_code: '#dd494b'},
        {value: '5.5-5.75', lower_limit: '', upper_limit: '', colour_code: '#f09377'},
        {value: '5.75-6', lower_limit: '', upper_limit: '', colour_code: '#f5c1a9'},
        {value: '6-6.25', lower_limit: '', upper_limit: '', colour_code: '#f7e5dd'},
        {value: '6.25-6.5', lower_limit: '', upper_limit: '', colour_code: '#e1ecf2'},
        {value: '6.5-14', lower_limit: '', upper_limit: '', colour_code: '#b4d6e7'}
    ];

    var PIndex_legend = [
        {value: '2-', lower_limit: '', upper_limit: '', colour_code: '#5abe73'},
        {value: '2', lower_limit: '', upper_limit: '', colour_code: '#74f595'},
        {value: '2+', lower_limit: '', upper_limit: '', colour_code: '#367345'},
        {value: '3-', lower_limit: '', upper_limit: '', colour_code: '#21472b'}
    ];

    var KIndex_legend = [
        {value: '1', lower_limit: '', upper_limit: '', colour_code: '#ff7f00'},
        {value: '1+', lower_limit: '', upper_limit: '', colour_code: '#fdf108'},
        {value: '2', lower_limit: '', upper_limit: '', colour_code: '#74c476'},
        {value: '2+', lower_limit: '', upper_limit: '', colour_code: '#31a354'}
    ];

    var MgIndex_legend = [
        {value: '2-', lower_limit: '', upper_limit: '', colour_code: '#bae4b3'},
        {value: '2', lower_limit: '', upper_limit: '', colour_code: '#edf8e9'},
        {value: '2+', lower_limit: '', upper_limit: '', colour_code: '#74c476'},
        {value: '3-', lower_limit: '', upper_limit: '', colour_code: '#31a354'}
    ];

    return {
        map_parameters: [
            {
                name: 'Topography',
                parameters: [
                    {
                        name: 'Field boundary',
                        value: 'None',
                        unit: '',
                        legend: null,
                        disabled: false
                    },
                    {
                        name: 'Elevation',
                        value: 'Elevation_',
                        unit: 'm',
                        legend: elevation_legend,
                        disabled: false
                    }
                ]
            },
            {
                name: 'Soil',
                parameters: [
                    {
                        name: 'Soil pH',
                        value: 'pH',
                        unit: '',
                        legend: pH_legend,
                        disabled: false
                    },
                    {
                        name: 'Soil P Index',
                        value: 'P_Inx',
                        unit: '',
                        legend: PIndex_legend,
                        disabled: false
                    },
                    {
                        name: 'Soil K Index',
                        value: 'K_Inx',
                        unit: '',
                        legend: KIndex_legend,
                        disabled: false
                    },
                    {
                        name: 'Soil Mg Index',
                        value: 'Mg_Inx',
                        unit: '',
                        legend: MgIndex_legend,
                        disabled: false
                    }
                ]
            },
            {
                name: 'Satellite',
                parameters: [
                    {
                        name: 'Aerial Image',
                        value: 'aerial-image-slug',
                        unit: '',
                        disabled: false
                    },
                    {name: 'NDVI', value: 'ndvi-slug', unit: '', disabled: true}
                ]
            }
        ]
    };
});

