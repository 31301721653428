angular.module('QoipApp').factory('QoipGrowthStageFactory', function ($q) {
    //Generating Secondary Growth Stage Number 0 to 99
    var growthStages = [];
    for (var i = 0; i < 100; i++) {
        var gsDict = {};
        gsDict = {name: 'GS ' + i.toString(), value: i.toString()};
        growthStages.push(gsDict);
    }
    return {
        query: function () {
            return $q(function (resolve) {
                resolve(growthStages);
            });
        }
    };
});

