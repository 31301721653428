angular.module('QoipApp').factory('QoipSatelliteDataFactory', function ($q) {
    var satelliteDataDetails = {
        "plot-003-slug": {
            "url": "https://storage.googleapis.com/esl-qoip-images/Clumps%20%26%20Garden_S2A_20190227_10m_RGB_3857.jpg",
            "bounds": {
                "top_left_lon": -3.4004471498788056,
                "top_left_lat": 56.3530201585950792,
                "bottom_right_lon": -3.3879636406507161,
                "bottom_right_lat": 56.3589254757456715,
            },
        },
    };
    return {
        get: function (plotslug) {
            return $q(function (resolve) {
                if(satelliteDataDetails.hasOwnProperty(plotslug)) {
                    resolve({"satellitelayer": satelliteDataDetails[plotslug]});
                }
                else{
                    resolve(null);
                }
            });
        }
    };
});

