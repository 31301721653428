angular.module("ShareWithApp").controller("ObjectAssignmentAddEditController",
        function ($scope, $rootScope, $mdDialog, ObjectAssignmentFactory,
            ShareWithConstantsFactory, ShareWithEmailSearchFactory,
            NgTableParams, $filter, $khMessage, object_slug, object_type, GenericUtilsService) {
            $scope.initObject = function () {
                $scope.email_address = "";
                $scope.object_assign = {};
                $scope.object_type = object_type;
            };
            $scope.initObject();

            $scope.saveAssignObject = function () {
                $khMessage.show("Assign permission...", 10000);
                $scope.object_assign.object_slug = object_slug;
                $scope.object_assign.object_type = object_type;
                ObjectAssignmentFactory.update($scope.object_assign,
                    function (success_data) {
                        $khMessage.show("Shared and set permissions successfully.", 10000);
                        $mdDialog.hide(success_data);
                    },
                    function (error_data) {
                        $khMessage.show("Failed to share and set Permissions.", 10000);
                    }
                )
            };

            $scope.isFormInvalid = function () {
                if (GenericUtilsService.isEmpty($scope.object_assign.object_assign_user) && GenericUtilsService.isEmpty($scope.object_assign.object_assign_user) && GenericUtilsService.isEmpty($scope.newShareWith)) {
                    return true;
                }
                if ($scope.newShareWith
                    && $scope.newShareWith.object_assign_user
                    && (!$scope.newShareWith.object_assign_user.user_id
                    || !$scope.newShareWith.object_assign_user.permission)
                ) {
                    return true;
                }
                if ($scope.newShareWith
                    && $scope.newShareWith.object_assign_team
                    && ( !$scope.newShareWith.object_assign_team.team_id
                    || !$scope.newShareWith.object_assign_team.permission)
                ) {
                    return true;
                }
                return false;
            };

            $scope.searchEmailAddress = function (email_address) {
                ShareWithEmailSearchFactory.get({
                    object_slug: object_slug,
                    email_address: email_address,
                    content: "assingment"
                }, function (success_data) {
                    $scope.object_assign.user_id = success_data.user_id;
                    $scope.assign_to = success_data;
                    $scope.showAssignToDiv = true;
                }, function (error_data) {
                    $scope.assign_to = {};
                    $scope.showAssignToDiv = false;
                    if (error_data.data && error_data.data.message) {
                        $khMessage.show(error_data.data.message, 10000);
                    }
                });
            };
        }

);