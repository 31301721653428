angular.module("ShareWithApp").controller("ShareWithAddEditController",
    function ($scope, $rootScope, $mdDialog, ShareWithFactory,
              ShareWithConstantsFactory, ShareWithEmailSearchFactory,
              NgTableParams, $filter, $khMessage, object_slug, object_type, GenericUtilsService) {
        var ApplicationHelpers = applicationHelpers();

        $scope.initObject = function () {
            $scope.email_address = "";
            $scope.newShareWith = {};
            $scope.share_with = {};
            $scope.showShareWithNewForm = false;
            $scope.object_type = object_type;
        };
        $scope.initObject();

        ShareWithConstantsFactory.get({user: user_id}, function (success_data) {
            $scope.permissions = success_data.permissions;
            ShareWithFactory.get({object_slug: object_slug, object_type: object_type}, function (success_data) {
                $scope.share_with = success_data;
            });
        });

        $scope.saveShareWith = function () {
            if (!GenericUtilsService.isEmpty($scope.newShareWith.share_with_user)) {
                ApplicationHelpers.addNewUserPermsToExistingPerms();
            }
            if (!GenericUtilsService.isEmpty($scope.newShareWith.share_with_team)) {
                ApplicationHelpers.addNewTeamPermsToExistingPerms();
            }
            $khMessage.show("Sharing and setting Permissions...", 10000);
            $scope.share_with.object_slug = object_slug;
            $scope.share_with.object_type = object_type;
            ShareWithFactory.update($scope.share_with,
                function (success_data) {
                    if (object_type == "Protocol") {
                        $khMessage.show("Shared and set permissions successfully. Selected Users and Trial App Admins will be notified by Email.", 10000);
                    }
                    else {
                        $khMessage.show("Shared and set permissions successfully.", 10000);

                    }
                    $mdDialog.hide(success_data);

                },
                function (error_data) {
                    $khMessage.show("Failed to share and set Permissions.", 10000);
                }
            )
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        $scope.searchEmailAddress = function () {
            ShareWithEmailSearchFactory.get({
                object_slug: object_slug,
                email_address: $scope.email_address,
                object_type: object_type
            }, function (success_data) {
                $scope.newShareWith = success_data;
                $scope.showShareWithNewForm = true;
            }, function (error_data) {
                $scope.newShareWith = {};
                $scope.showShareWithNewForm = false;
                if (error_data.data && error_data.data.message) {
                    $khMessage.show(error_data.data.message, 10000);
                }
            });
        };

        $scope.removeRow = function (obj_to_delete) {
            if (obj_to_delete == "new_user_perm") {
                delete $scope.newShareWith.share_with_user;
            }
            else if (obj_to_delete == "new_team_perm") {
                delete $scope.newShareWith.share_with_team;
            }
        };

        $scope.isFormInvalid = function () {
            if (GenericUtilsService.isEmpty($scope.share_with.share_with_user) && GenericUtilsService.isEmpty($scope.share_with.share_with_team) && GenericUtilsService.isEmpty($scope.newShareWith)) {
                return true;
            }
            if ($scope.newShareWith
                && $scope.newShareWith.share_with_user
                && (!$scope.newShareWith.share_with_user.user_id
                || !$scope.newShareWith.share_with_user.permission)
            ) {
                return true;
            }
            if ($scope.newShareWith
                && $scope.newShareWith.share_with_team
                && ( !$scope.newShareWith.share_with_team.team_id
                || !$scope.newShareWith.share_with_team.permission)
            ) {
                return true;
            }
            return false;
        };

        function applicationHelpers() {
            return {
                addNewUserPermsToExistingPerms: function () {
                    var existing_user_permission = false;
                    if ($scope.share_with.share_with_user) {
                        for (var i = 0; i < $scope.share_with.share_with_user.length; i++) {
                            if ($scope.share_with.share_with_user[i].user_id == $scope.newShareWith.share_with_user.user_id) {
                                existing_user_permission = true;
                                $scope.share_with.share_with_user[i] = $scope.newShareWith.share_with_user
                            }
                        }
                    }
                    if (!existing_user_permission) {
                        if (!$scope.share_with.share_with_user) {
                            $scope.share_with.share_with_user = [];
                        }
                        $scope.share_with.share_with_user.push($scope.newShareWith.share_with_user)
                    }
                },

                addNewTeamPermsToExistingPerms: function () {
                    var existing_team_permission = false;
                    if ($scope.share_with.share_with_team && $scope.newShareWith.share_with_team) {
                        for (var i = 0; i < $scope.share_with.share_with_team.length; i++) {
                            if ($scope.share_with.share_with_team[i].team_id == $scope.newShareWith.share_with_team.team_id) {
                                existing_team_permission = true;
                                $scope.share_with.share_with_team[i] = $scope.newShareWith.share_with_team
                            }
                        }
                    }
                    if (!existing_team_permission) {
                        if (!$scope.share_with.share_with_team) {
                            $scope.share_with.share_with_team = [];
                        }
                        $scope.share_with.share_with_team.push($scope.newShareWith.share_with_team)
                    }
                }
            }
        }
    }
);