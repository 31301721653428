angular.module('QoipApp').factory('QoipApplicationMethodsFactory', function ($q) {

    var applicationMethods = [
        {name: 'Granular', value: 'granular'},
        {name: 'Liquid', value: 'liquid'},
    ];
    return {
        query: function () {
            return $q(function (resolve) {
                resolve(applicationMethods);
            });
        }
    };
});

