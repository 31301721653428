angular.module("ShareWithApp").factory("ShareWithFactory", ["$resource", function ($resource) {
    return $resource("/api/v1.0/profiles/sharewith/", {}, {
        get: {
            params: {object_slug: '@object_slug'},
            url: '/api/v1.0/profiles/sharewith/:object_slug/',
            method: 'GET'
        },
        update: {
            params: {object_slug: '@object_slug'},
            url: '/api/v1.0/profiles/sharewith/:object_slug/',
            method: 'PUT'
        }
    })
}]);