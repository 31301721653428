angular.module('QoipApp').component('qoipModelInput', {
    templateUrl: '/static/assets/khapps/quakeroats/model_input/qoip-model-input.component.html',
    controller: function ($q, $resource, $khMessage, QoipApplicationMethodsFactory,
                          QoipGrowthStageFactory, qoipPlotSelectorService, QoipGrowerPlotListFactory) {
        var self = this;
        self.is_loading = false;
        self.active_field = null;
        self.season_data = $resource(
            '/api/qoip/field/:field_id/season-data/',
            {field_id: '@field_id'}
        );
        self.max_date = new Date();
        self.data = {
            tillers: {
                date: new Date(),
                value: null,
                meta_value: null,
                actual: false
            },
            nitrogen: {
                date: new Date(),
                value: null,
                meta_value: null,
                actual: null
            }
        };
        self.sub = qoipPlotSelectorService.field$().subscribe(function(){
            if (qoipPlotSelectorService.value()) {
                self.active_field = qoipPlotSelectorService.value();
            }
        });
        self.$onInit = function () {
            $q.all([
                QoipApplicationMethodsFactory.query(),
                QoipGrowthStageFactory.query()]).then(function (results) {
                    self.applicationMethods = results[0];
                    self.growthStages = results[1];
            });
        };
        self.save = function(data_type) {
            var data = {
                field_id: self.active_field.id,
                type: data_type,
                date: self.data[data_type].date,
                value: self.data[data_type].value,
                meta_value: self.data[data_type].meta_value,
                actual: self.data[data_type].actual
            };
            self.is_loading = true;
            self.season_data.save(data).$promise.then(function() {
                QoipGrowerPlotListFactory.field(self.active_field.id).then(function(result){
                    self.active_field = result.data;
                    $khMessage.show(
                        'Assessment data has been updated successfully.');
                    self.is_loading = false;
                });
            },
            function(error){
                $khMessage.show('Error. Assessment data could not be saved.');
                self.is_loading = false;
            });
        };
    }
});
