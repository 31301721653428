angular.module('QoipApp').component('qoipMap', {
    templateUrl: '/static/assets/khapps/quakeroats/map/qoip-map.component.html',
    controller: function ($timeout, $resource, $khMessage, leafletData, FieldPlotCropFactory,
                          qoipPlotSelectorService, QoipMapParameterListFactory,
                          QoipSoilDataFactory, qoipMapService) {
        var self = this;
        var mapid = "landingMap";
        var sub;
        self.map = null;
        self.active_field = qoipPlotSelectorService.value();
        self.parameterLegend = null;
        self.ndvi = $resource('/api/qoip/field/:field_id/ndvi/', {field_id: "@field_id"})
        self.overlay_type = 'boundary';
        self.ndvi_is_loading = false;
        self.satellite_is_loading = false;
        self.$onInit = function () {
            self.map_parameter_list = QoipMapParameterListFactory.map_parameters;
            self.selectedMapParameter = self.map_parameter_list[0].parameters[0];
            self.layers = {
                googleHybrid: {
                    name: 'Google Hybrid',
                    type: 'google',
                    layerType: 'HYBRID',
                    noWarp: true
                },
                ESRI_satellite_layer: {
                    name: 'ESRI Satellite',
                    type: 'xyz',
                    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    noWrap: true
                },
                Esri_WorldGrayCanvas: {
                    name: 'ESRI World Gray',
                    type: 'xyz',
                    url: 'http://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
                    maxZoom: 16,
                    attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
                    noWrap: true
                },
                OSM: {
                    name: 'Openstreetmap',
                    type: 'xyz',
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    noWrap: true
                }
            };
            createBaseMap();
            sub = qoipPlotSelectorService.field$().subscribe(function() {
                if (qoipPlotSelectorService.value()) {
                    self.active_field = qoipPlotSelectorService.value();
                    qoipMapService.reload(self.active_field);
                }
            });
            qoipMapService.reload(self.active_field);
        };

        self.ndvi_get_colour = function(v) {
            return v > 0.9 ? 'rgb(26,150,65)' :
                   v > 0.8 ? 'rgb(88,180,83)' :
                   v > 0.7 ? 'rgb(151,210,101)' :
                   v > 0.6 ? 'rgb(196,230,135)' :
                   v > 0.5 ? 'rgb(235,247,173)' :
                   v > 0.4 ? 'rgb(255,237,171)' :
                   v > 0.3 ? 'rgb(254,201,129)' :
                   v > 0.2 ? 'rgb(249,158,89)' :
                   v > 0.1 ? 'rgb(232,91,59)' :
                             'rgb(215,25,28)';
        };

        // clean up the sub
        self.$onDestroy = function () {
            sub.dispose();
        };

        self.displayBounds = function() {
            self.removeLegend();
            if(self.satellite_overlay) {
                self.map.removeLayer(self.satellite_overlay);
            };
            if(self.ndvi_overlay) {
                self.map.removeLayer(self.ndvi_overlay);
            };
            qoipMapService.reload(self.active_field);
        };

        self.displayNDVI = function() {
            var resource = null;
            self.ndvi_is_loading = true;
            self.removeLegend();
            self.legend = L.control({position: 'bottomleft'});
            if (self.active_field.model_data.images.ndvi) {
                if(self.satellite_overlay) {
                    self.map.removeLayer(self.satellite_overlay);
                };
                if(self.ndvi_overlay) {
                    self.map.removeLayer(self.ndvi_overlay);
                };
                self.ndvi.get({'field_id': self.active_field.id}).$promise.then(function(data){
                    self.ndvi_overlay = L.geoJson(data.data, {style: function style(feature) {
                        return {
                            fillColor: self.ndvi_get_colour(feature.properties[data.property]),
                            weight: 0,
                            opacity: 0,
                            dashArray: '',
                            fillOpacity: 1
                        };
                    }})
                    self.ndvi_overlay.addTo(self.map);
                    self.ndvi_overlay.bringToFront();
                    self.map.fitBounds(self.ndvi_overlay.getBounds());
                    self.legend.onAdd = function (map) {

                        var div = L.DomUtil.create('div', 'info legend'),
                            grades = [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
                            labels = [];

                        // loop through our density intervals and generate a label with a colored square for each interval
                        for (var i = 0; i < grades.length - 1; i++) {
                            div.innerHTML +=
                                '<i style="background:' + self.ndvi_get_colour(grades[i + 1]) + '; display: inline-block; padding: 6px;"></i> ' +
                                grades[i] + (grades[i + 1] ? ' &ndash; ' + grades[i + 1] + '<br>' : '+');
                        }

                        return div;
                    };

                    self.legend.addTo(self.map);
                    self.ndvi_is_loading = false;
                },
                function(error){
                    $khMessage.show('Error. NDVI data could not be displayed.');
                    self.ndvi_is_loading = false;
                });
            }
        };

        self.displaySatellite = function() {
            self.satellite_is_loading = true;
            var imageUrl = self.active_field.model_data.images.satellite.url,
                imageBounds = [[
                        self.active_field.model_data.images.satellite.bounds.top_left_lon,
                        self.active_field.model_data.images.satellite.bounds.top_left_lat
                    ],
                    [
                        self.active_field.model_data.images.satellite.bounds.bottom_right_lon,
                        self.active_field.model_data.images.satellite.bounds.bottom_right_lat
                ]];
            if(self.satellite_overlay) {
                self.map.removeLayer(self.satellite_overlay);
            };
            if(self.ndvi_overlay) {
                self.map.removeLayer(self.ndvi_overlay);
            };
            self.removeLegend();
            self.satellite_overlay = L.imageOverlay(imageUrl, imageBounds)
            self.satellite_overlay.addTo(self.map);
            self.satellite_overlay.bringToFront();
            self.map.fitBounds(imageBounds);
            self.satellite_is_loading = false;
        };

        self.removeLegend = function(){
            try {
                self.map.removeControl(self.legend);
            } catch {
            }
        };
        //Setting Map Data
        function createBaseMap() {
            leafletData.getMap(mapid).then(function (map) {
                self.map = map;
                map.invalidateSize.bind(map);
                L.control.scale().addTo(map);
                setTimeout(map.invalidateSize.bind(map));
            });
            angular.extend(self, {
                defaults: {
                    maxZoom: 22,
                    minZoom: 2,
                    worldCopyJump: true,
                    doubleClickZoom: true,
                    scrollWheelZoom: false,
                },
                layers: {
                    baselayers: {
                        googleHybrid: self.layers.googleHybrid,
                        ESRI: self.layers.ESRI_satellite_layer,
                        ESRIWC: self.layers.Esri_WorldGrayCanvas,
                        OSM: self.layers.OSM
                    },
                    tileLayerOptions: {
                        opacity: 0.9,
                        detectRetina: true,
                        reuseTiles: true
                    },
                    overlays: {
                        draw: {
                            name: 'draw',
                            type: 'markercluster',
                            visible: true,
                            layerParams: {
                                showOnSelector: false
                            }
                        }
                    },
                    controls: {
                        zoomControl: {
                            zoomControl: false,
                            position: 'bottomright'
                        },
                        fullscreen: {position: 'topleft'},
                        scale: {scale: true, position: 'bottomright'},
                        draw: {
                            draw: {
                                marker: false,
                                polyline: false,
                                polygon: false,
                                rectangle: false,
                                circle: false
                            }
                        }
                    }
                }
            });
        }
    }
});
