angular.module('QoipApp').component('qoipModelRun', {
    templateUrl: '/static/assets/khapps/quakeroats/model_run/qoip-model-run.component.html',
    controller: function (qoipPlotSelectorService) {
        var self = this;
        self.active_field = null;
        self.sub = qoipPlotSelectorService.field$().subscribe(function(){
            self.active_field = qoipPlotSelectorService.value();
        });

        self.$onDestroy = function () {
            self.sub.dispose();
        };

    }
});
