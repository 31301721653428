angular.module('QoipApp').service('qoipPlotSelectorService', function (QoipGrowerPlotListFactory) {
    'use strict';
    const self = this;
    const subject = new Rx.BehaviorSubject();

    self.field$ = function () {
        return subject;
    };

    self.setField = function(field) {
        QoipGrowerPlotListFactory.field(field.id).then(function(result) {
            self.field_detail = result.data;
            subject.onNext(self.field_detail);
        });
    };

    self.value = function(){
        return subject.getValue();
    };
});
